import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { tooManyAttempts } from '~constants/billing-info';
import { ToastIds } from '~constants/toast-ids';
import {
    getBillingErrorMessage,
    isBadRequestError,
    isConflictError,
    isTooManyRequests,
} from '~utils/errors';
import { clearOutdatedSignupData, IRequestState, LoginResponse } from '~utils/hn-api';
import {
    clearToastQueue,
    getDescriptiveErrorText,
    showApiErrorToast,
    showErrorToast,
} from '~utils/toast-utils';
import {
    parseAndSaveUserInfo,
    removeSignupId,
    removeSignupToken,
    setAccessToken,
    setRefreshToken,
    setSignupId,
    setSignupToken,
    setSubscriberId,
    setUserId,
} from '~utils/tokens';
import { setAccountInfo, setSignupQuery } from '../account-info';
import {
    addToWaitingListRequest,
    createAccount,
    createAccountWithoutCard,
    fetchSignupInfoRequest,
    getOnboardingPassedRequest,
    sendWaitingListEmailRequest,
    setSignupPersonalInfoRequest,
    updateBillingInfoRequest,
    updateOnboardingPassedRequest,
    updateSignupDeliveryInfoRequest,
    updateSignupPersonalInfoRequest,
} from './api';
import {
    ISignupBillingInfo,
    ISignupCardInfo,
    ISignupDeliveryInfo,
    ISignupInfo,
    ISignupInfoState,
    ISignupOnboarding,
    ISignupOnboardingState,
    ISignupPersonalInfo,
    ISignupState,
    IWaitingListForm,
    SignupFailed,
    SignupResponse,
} from './types';

const initialState = {
    info: {
        data: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            address: '',
            aptSuite: '',
            city: '',
            state: '',
            zipCode: '',
            phone: '',
            companyName: '',
            businessAddress: false,
            billingFirstName: '',
            billingLastName: '',
            billingAddress: '',
            billingAptSuite: '',
            billingCity: '',
            billingState: '',
            billingZipCode: '',
            billingPhone: '',
            billingEmail: '',
            cardNumber: '',
            cvv: '',
            expiration: '',
        } as ISignupInfo,
        isSucceed: false,
        isLoading: false,
    } as ISignupInfoState,
    changePersonalInfo: {
        isSucceed: false,
        isLoading: false,
    } as IRequestState,
    updateDeliveryInfo: {
        isSucceed: false,
        isLoading: false,
    } as IRequestState,
    updateBillingInfo: {
        isSucceed: false,
        isLoading: false,
    } as IRequestState,
    isOnboardingPassed: {
        data: {} as ISignupOnboarding,
        isSucceed: false,
        isLoading: false,
    } as ISignupOnboardingState,
    createAccount: {
        isSucceed: false,
        isLoading: false,
    } as IRequestState,
    addToWaitingList: {
        isSucceed: false,
        isLoading: false,
    } as IRequestState,
    onWaitingList: false,
    addedToWaitingList: false,
} as ISignupState;

export const fetchSignupInfo = createAsyncThunk('signup/info', async () => {
    try {
        const { data } = await fetchSignupInfoRequest();
        return data;
    } catch (error: any) {
        const axiosError: AxiosError<SignupFailed, any> = error;

        if (!axiosError.response) {
            throw error;
        }
        if (axiosError.response.status === 401 || axiosError.response.status === 403) {
            clearOutdatedSignupData();
        }
    }
});

export const saveSignupInfo = createAsyncThunk(
    'signup/saveInfo',
    (signupInfo: ISignupInfo) => signupInfo,
);

export const setSignupPersonalInfoAsync = createAsyncThunk(
    '/signup/setPersonalInfo',
    async (personalInfo: ISignupPersonalInfo, { rejectWithValue }) => {
        try {
            const response = await setSignupPersonalInfoRequest(personalInfo);
            const {
                signup: { PK_signupID: signupId },
                token,
            } = response.data as SignupResponse;
            setSignupId(signupId);
            setSignupToken(token);

            return personalInfo;
        } catch (error: any) {
            const axiosError: AxiosError<SignupFailed, any> = error;

            if (!axiosError.response) {
                throw error;
            }

            if (isConflictError(error)) {
                showApiErrorToast(error, ToastIds.EMAILS_ALREADY_IN_USE);
                throw error;
            }

            return rejectWithValue(axiosError.response.data);
        }
    },
);

export const updateSignupPersonalInfoAsync = createAsyncThunk(
    '/signup/updatePersonalInfo',
    async (personalInfo: ISignupPersonalInfo, { rejectWithValue }) => {
        try {
            const response = await updateSignupPersonalInfoRequest(personalInfo);
            return { ...response.data, password: personalInfo.password } as ISignupInfo;
        } catch (error: any) {
            const axiosError: AxiosError<SignupFailed, any> = error;

            if (!axiosError.response) {
                throw error;
            }
            if (axiosError.response.status === 401 || axiosError.response.status === 403) {
                clearOutdatedSignupData();
            }

            if (isConflictError(error)) {
                if (error?.response?.data?.description) {
                    showErrorToast(getDescriptiveErrorText(error), ToastIds.EMAILS_ALREADY_IN_USE);
                } else {
                    showApiErrorToast(error, ToastIds.EMAILS_ALREADY_IN_USE);
                }
                throw error;
            }

            return rejectWithValue(axiosError.response.data);
        }
    },
);

export const updateSignupDeliveryInfoAsync = createAsyncThunk(
    '/signup/updateDeliveryInfo',
    async (deliveryInfo: ISignupDeliveryInfo, { rejectWithValue }) => {
        try {
            const response = await updateSignupDeliveryInfoRequest(deliveryInfo);
            // eslint-disable-next-line camelcase
            const { FK_PK_subscriberID } = response.data as ISignupInfo;
            setSubscriberId(FK_PK_subscriberID);
            return response.data as ISignupInfo;
        } catch (error: any) {
            const axiosError: AxiosError<SignupFailed, any> = error;

            if (!axiosError.response) {
                throw error;
            }
            if (axiosError.response.status === 401 || axiosError.response.status === 403) {
                clearOutdatedSignupData();
            }

            if (isConflictError(error)) {
                if (error?.response?.data?.description) {
                    showErrorToast(getDescriptiveErrorText(error), ToastIds.EMAILS_ALREADY_IN_USE);
                } else {
                    showApiErrorToast(error, ToastIds.EMAILS_ALREADY_IN_USE);
                }
                throw error;
            }

            return rejectWithValue(axiosError.response.data);
        }
    },
);

export const updateSignupBillingInfoAsync = createAsyncThunk(
    '/signup/updateBillingInfo',
    async (billingInfo: ISignupBillingInfo, { rejectWithValue }) => {
        try {
            const response = await updateBillingInfoRequest(billingInfo);
            return response.data as ISignupInfo;
        } catch (error: any) {
            const axiosError: AxiosError<SignupFailed, any> = error;

            if (!axiosError.response) {
                throw error;
            }
            if (axiosError.response.status === 401 || axiosError.response.status === 403) {
                clearOutdatedSignupData();
            }

            if (isConflictError(error)) {
                if (error?.response?.data?.description) {
                    showErrorToast(getDescriptiveErrorText(error), ToastIds.EMAILS_ALREADY_IN_USE);
                } else {
                    showApiErrorToast(error, ToastIds.EMAILS_ALREADY_IN_USE);
                }
                throw error;
            }

            return rejectWithValue(axiosError.response.data);
        }
    },
);

export const setOnboardingPassedAsync = createAsyncThunk(
    '/signup/setOnboardingPassed',
    async () => {
        const { data } = await updateOnboardingPassedRequest(/* onboardingPassed: */ true);
        return data as ISignupOnboarding;
    },
);

export const isOnboardingPassedAsync = createAsyncThunk('/signup/isOnboardingPassed', async () => {
    const { data } = await getOnboardingPassedRequest();
    return data as ISignupOnboarding;
});

export const createAccountAsync = createAsyncThunk(
    '/signup/createAccount',
    async (cardInfo: ISignupCardInfo, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await createAccount(cardInfo);

            const userInfo = parseAndSaveUserInfo(data);
            dispatch(setAccountInfo(userInfo));
            dispatch(setSignupQuery(true));

            removeSignupToken();
            removeSignupId();
            clearToastQueue();
        } catch (error: any) {
            const axiosError: AxiosError<SignupFailed, any> = error;

            if (!axiosError.response) {
                throw error;
            }

            if (axiosError.response.status === 401 || axiosError.response.status === 403) {
                clearOutdatedSignupData();
            }

            if (isBadRequestError(error)) {
                showErrorToast(getBillingErrorMessage(error), ToastIds.ADD_BILLING_ERROR);
            }

            if (isTooManyRequests(error)) {
                showErrorToast(tooManyAttempts, ToastIds.TOO_MANY_UPDATE_BILLING_REQUESTS);
            }

            if (isConflictError(error)) {
                if (error?.response?.data?.description) {
                    showErrorToast(getDescriptiveErrorText(error), ToastIds.EMAILS_ALREADY_IN_USE);
                } else {
                    showApiErrorToast(error, ToastIds.EMAILS_ALREADY_IN_USE);
                }
                throw error;
            }

            return rejectWithValue(axiosError.response.data);
        }
    },
);

export const createAccountWithoutCardAsync = createAsyncThunk(
    '/signup/createAccountWithoutCard',
    async (promoCodeForm: any, { rejectWithValue }) => {
        try {
            const response = await createAccountWithoutCard(promoCodeForm);
            const {
                user: {
                    PK_usersID: userId,
                    FK_PK_subscriberID: subscriberId,
                    FK_PK_subscriberID: signupId,
                },
                tokens: { accessToken, refreshToken },
            } = response.data as LoginResponse;
            setAccessToken(accessToken);
            setRefreshToken(refreshToken);
            setSubscriberId(subscriberId);
            setSignupId(signupId);
            setUserId(userId);

            removeSignupToken();
            removeSignupId();

            clearToastQueue();
        } catch (error: any) {
            const axiosError: AxiosError<SignupFailed, any> = error;

            if (!axiosError.response) {
                throw error;
            }
            if (axiosError.response.status === 401 || axiosError.response.status === 403) {
                clearOutdatedSignupData();
            }

            if (isConflictError(error)) {
                showApiErrorToast(error, ToastIds.EMAILS_ALREADY_IN_USE);
                throw error;
            }

            return rejectWithValue(axiosError.response.data);
        }
    },
);

export const addToWaitingListAsync = createAsyncThunk(
    '/signup/addToWaitingList',
    async (waitingList: IWaitingListForm, { rejectWithValue }) => {
        try {
            await addToWaitingListRequest(waitingList);
        } catch (error: any) {
            const axiosError: AxiosError<SignupFailed, any> = error;

            if (!axiosError.response) {
                throw error;
            }

            if (axiosError.response.status === 401 || axiosError.response.status === 403) {
                clearOutdatedSignupData();
            }

            if (axiosError.response.status !== 409) {
                return rejectWithValue(axiosError.response.data);
            }

            if (axiosError.response.status === 409) {
                return rejectWithValue(axiosError.response.data);
            }
        }
    },
);

export const sendWaitingListEmailAsync = createAsyncThunk(
    '/signup/waitList/email',
    async (waitingList: IWaitingListForm, { rejectWithValue }) => {
        try {
            await sendWaitingListEmailRequest(waitingList);
        } catch (error: any) {
            const axiosError: AxiosError<SignupFailed, any> = error;

            if (!axiosError.response) {
                throw error;
            }

            if (axiosError.response.status === 401 || axiosError.response.status === 403) {
                clearOutdatedSignupData();
            }
        }
    },
);

const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        resetSignup: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveSignupInfo.fulfilled, (state, action) => {
                state.info.isLoading = false;
                state.info.isSucceed = true;
                state.info.data = { ...state.info.data, ...action.payload };
            })

            .addCase(fetchSignupInfo.pending, (state) => {
                state.info.isLoading = true;
            })
            .addCase(fetchSignupInfo.fulfilled, (state, action) => {
                state.info.isLoading = false;
                state.info.isSucceed = true;
                state.info.data = { ...state.info.data, ...action.payload };
            })
            .addCase(fetchSignupInfo.rejected, (state) => {
                state.info.isLoading = false;
                state.info.isSucceed = false;
            })

            .addCase(setSignupPersonalInfoAsync.pending, (state) => {
                state.changePersonalInfo.isLoading = true;
                state.changePersonalInfo.isSucceed = false;
                state.onWaitingList = false;
                state.addedToWaitingList = false;
            })
            .addCase(setSignupPersonalInfoAsync.fulfilled, (state, action) => {
                state.changePersonalInfo.isLoading = false;
                state.changePersonalInfo.isSucceed = true;
                state.info.data = { ...state.info.data, ...action.payload };
                state.onWaitingList = false;
                state.addedToWaitingList = false;
            })
            .addCase(setSignupPersonalInfoAsync.rejected, (state) => {
                state.changePersonalInfo.isLoading = false;
                state.changePersonalInfo.isSucceed = false;
                state.onWaitingList = false;
                state.addedToWaitingList = false;
            })

            .addCase(updateSignupPersonalInfoAsync.pending, (state) => {
                state.changePersonalInfo.isLoading = true;
                state.changePersonalInfo.isSucceed = false;
                state.onWaitingList = false;
                state.addedToWaitingList = false;
            })
            .addCase(updateSignupPersonalInfoAsync.fulfilled, (state, action) => {
                state.changePersonalInfo.isLoading = false;
                state.changePersonalInfo.isSucceed = true;
                state.info.data = { ...state.info.data, ...action.payload };
                state.onWaitingList = false;
                state.addedToWaitingList = false;
            })
            .addCase(updateSignupPersonalInfoAsync.rejected, (state) => {
                state.changePersonalInfo.isLoading = false;
                state.changePersonalInfo.isSucceed = false;
                state.onWaitingList = false;
                state.addedToWaitingList = false;
            })

            .addCase(updateSignupDeliveryInfoAsync.pending, (state) => {
                state.updateDeliveryInfo.isLoading = true;
                state.updateDeliveryInfo.isSucceed = false;
                state.onWaitingList = false;
                state.addedToWaitingList = false;
            })
            .addCase(updateSignupDeliveryInfoAsync.fulfilled, (state, action) => {
                state.updateDeliveryInfo.isLoading = false;
                state.updateDeliveryInfo.isSucceed = true;
                state.info.data = { ...state.info.data, ...action.payload };
                state.onWaitingList = false;
                state.addedToWaitingList = false;
            })
            .addCase(updateSignupDeliveryInfoAsync.rejected, (state) => {
                state.updateDeliveryInfo.isLoading = false;
                state.updateDeliveryInfo.isSucceed = false;
                state.onWaitingList = false;
                state.addedToWaitingList = false;
            })

            .addCase(updateSignupBillingInfoAsync.pending, (state) => {
                state.updateBillingInfo.isLoading = true;
                state.updateBillingInfo.isSucceed = false;
            })
            .addCase(updateSignupBillingInfoAsync.fulfilled, (state, action) => {
                state.updateBillingInfo.isLoading = false;
                state.updateBillingInfo.isSucceed = true;
                state.info.data = { ...state.info.data, ...action.payload };
            })
            .addCase(updateSignupBillingInfoAsync.rejected, (state) => {
                state.updateBillingInfo.isLoading = false;
                state.updateBillingInfo.isSucceed = false;
            })

            .addCase(isOnboardingPassedAsync.pending, (state) => {
                state.isOnboardingPassed.isLoading = true;
                state.isOnboardingPassed.isSucceed = false;
            })
            .addCase(isOnboardingPassedAsync.fulfilled, (state, { payload }) => {
                state.isOnboardingPassed.data = payload;
                state.isOnboardingPassed.isLoading = false;
                state.isOnboardingPassed.isSucceed = true;
            })
            .addCase(isOnboardingPassedAsync.rejected, (state) => {
                state.isOnboardingPassed.isLoading = false;
                state.isOnboardingPassed.isSucceed = false;
            })

            .addCase(setOnboardingPassedAsync.pending, (state) => {
                state.isOnboardingPassed.isLoading = true;
                state.isOnboardingPassed.isSucceed = false;
            })
            .addCase(setOnboardingPassedAsync.fulfilled, (state, { payload }) => {
                state.isOnboardingPassed.data = payload;
                state.isOnboardingPassed.isLoading = false;
                state.isOnboardingPassed.isSucceed = true;
            })
            .addCase(setOnboardingPassedAsync.rejected, (state) => {
                state.isOnboardingPassed.isLoading = false;
                state.isOnboardingPassed.isSucceed = false;
            })

            .addCase(createAccountAsync.pending, (state) => {
                state.createAccount.isLoading = true;
                state.createAccount.isSucceed = false;
            })
            .addCase(createAccountAsync.fulfilled, (state) => {
                state.createAccount.isLoading = false;
                state.createAccount.isSucceed = true;
                state.info = { ...state.info, ...initialState.info };
            })
            .addCase(createAccountAsync.rejected, (state) => {
                state.createAccount.isLoading = false;
                state.createAccount.isSucceed = false;
            })

            .addCase(createAccountWithoutCardAsync.pending, (state) => {
                state.createAccount.isLoading = true;
                state.createAccount.isSucceed = false;
            })
            .addCase(createAccountWithoutCardAsync.fulfilled, (state) => {
                state.createAccount.isLoading = false;
                state.createAccount.isSucceed = true;
                state.info = { ...state.info, ...initialState.info };
            })
            .addCase(createAccountWithoutCardAsync.rejected, (state) => {
                state.createAccount.isLoading = false;
                state.createAccount.isSucceed = false;
            })

            .addCase(addToWaitingListAsync.pending, (state) => {
                state.addToWaitingList.isLoading = true;
                state.addToWaitingList.isSucceed = false;
                state.onWaitingList = false;
                state.addedToWaitingList = false;
            })
            .addCase(addToWaitingListAsync.fulfilled, (state) => {
                state.addToWaitingList.isLoading = false;
                state.addToWaitingList.isSucceed = true;
                state.onWaitingList = false;
                state.addedToWaitingList = true;
            })
            .addCase(addToWaitingListAsync.rejected, (state) => {
                state.addToWaitingList.isLoading = false;
                state.addToWaitingList.isSucceed = false;
                state.onWaitingList = true;
                state.addedToWaitingList = false;
            });
    },
});

export const { resetSignup } = signupSlice.actions;

export default signupSlice.reducer;
